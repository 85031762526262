import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './RedeemCode.module.css';

const RedeemCode = () => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Set API URL with fallback to localhost if the environment variable is not set
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    // Log the API URL to help with debugging
    console.log('Using API URL:', apiUrl);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = await axios.post(`${apiUrl}/api/redeem-code`, { code }); // Adjusted to use /api/ prefix
            const response = await axios.post(`${apiUrl}/redeem-code`, { code }); // Adjusted to use /api/ prefix
            if (response.data.success) {
                const { session_token, code: redeemedCode, credits } = response.data;
                
                // Save session details to local storage
                localStorage.setItem('session_token', session_token);
                localStorage.setItem('redeem_code', redeemedCode);
                localStorage.setItem('credits', credits);
                
                // Redirect to the test page without reloading the window
                navigate('/test-app', { replace: true });
            } else {
                setError(t('Invalid or already used code'));
            }
        } catch (error) {
            setError(t('Invalid or already used code'));
        }
    };

    const handleGoBack = () => {
        navigate('/'); // This will take the user back to the main page or home page
    };

    return (
        <div className={styles.container}>
            <h1>{t('Redeem Your Code')}</h1> 
            <form onSubmit={handleSubmit} className={styles.redeemForm}>
                <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder={t('Enter your code')}
                    className={styles.input}
                    maxLength="9"
                />
                <button type="submit" className={styles.button}>{t('Redeem')}</button> 
            </form>
            {error && <p className={styles.error}>{error}</p>}
            <button onClick={handleGoBack} className={styles.backButton}>
                {t('Back to Main Page')}
            </button>
        </div>
    );
};

export default RedeemCode;
