import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import './PurchaseCredits.css';
import { useTranslation } from 'react-i18next';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_test_51LXIwaKFBLgdLlQOtuOmYNp37MBnShnAsgNFPnG3BccFqQJ4N50lZJ4LdZvcCQelELPMbv00YAexuOQlhc4lbt2x00zPeWG73A');
// const stripePromise = loadStripe('pk_live_51LXIwaKFBLgdLlQOptTCquGgA2vq8adliMJlNBwMAIriZo2xGUtcufSJOJsGKQbFaIVrzMFtoMdLSB2GYGSIkn2d00symNrTwp');

const PurchaseCredits = () => {
  const [credits, setCredits] = useState(10); // Default to 10 credits
  const { user } = useAuth0();
  const { t } = useTranslation();

  // Use dynamic API URL
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise; // Get Stripe instance
      const response = await axios.post(`${apiUrl}/create-checkout-session`, {
        credits,
        user_id: user.sub
      });
      const { id } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      if (error) {
        console.error('Stripe redirect to checkout error', error);
      }
    } catch (error) {
      console.error('Error creating checkout session', error);
    }
  };

  return (
    <div className="purchase-credits">
      <h1>{t('Purchase Credits')}</h1>
      <div className="credits-selector">
        <label>
          {t('Select Credits')}:
          <select value={credits} onChange={(e) => setCredits(Number(e.target.value))}>
            <option value={10}>{t('10 Credits for 10 CHF')}</option>
            <option value={100}>{t('100 Credits for 80 CHF')}</option>
          </select>
        </label>
      </div>
      <button onClick={handleCheckout} className="stripe-buy-button">
        {t('Buy')} {credits === 100 ? t('100 Credits for 80 CHF') : t('10 Credits for 10 CHF')}
      </button>
    </div>
  );
};

export default PurchaseCredits;
