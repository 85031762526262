import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styles from './LandingPage.module.css';
import deepreturnLogo from '../../assets/images/deepreturn.png';
import SavingCalculator from '../../components/SavingCalculator';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import { FaCamera, FaGlobe, FaImage, FaPalette, FaMapMarkedAlt, FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';

const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogin = () => {
    navigate('/login', { state: { fromTryItOut: false } });
  };

  const handleTryItOut = () => {
    navigate('/login', { state: { fromTryItOut: true } });
  };

  const handleRedeemCode = () => {
    navigate('/redeem');
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

    // Only close the menu if in mobile view
    if (window.innerWidth <= 768) {
      toggleMenu();
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.landingPage}>
      <header className={styles.header}>
        <a href="https://deepreturn.com" target="_blank" rel="noopener noreferrer">
          <img src={deepreturnLogo} alt="DeepReturn Logo" className={styles.logo} />
        </a>

        <nav className={`${styles.navMenu} ${isMenuOpen && window.innerWidth <= 768 ? styles.open : ''}`}>
          <button onClick={() => scrollToSection('home')} className={styles.navButton}>
            {t('Home')}
          </button>
          <button onClick={() => scrollToSection('features')} className={styles.navButton}>
            {t('Features')}
          </button>
          <button onClick={() => scrollToSection('pricing')} className={styles.navButton}>
            {t('Pricing')}
          </button>
        </nav>

        <button className={styles.hamburgerMenu} onClick={toggleMenu}>
          <span className={styles.hamburgerIcon}></span>
        </button>

        <div className={styles.headerActions}>
          <LanguageSwitcher className={styles.languageSwitcher} />
        </div>
      </header>

      <section id="home" className={styles.hero}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>{t('Create Stunning Real Estate Listings Instantly')}</h1>
          <p className={styles.heroSubtitle}>
            {t('Upload your photos – our AI generates listings and gathers surrounding information automatically')}
          </p>
          <div className={styles.buttonContainer}>
            <button onClick={handleTryItOut} className={`${styles.baseButton} ${styles.ctaButton}`}>
              {t('Create Your Listing Now')}
            </button>
            <button onClick={handleLogin} className={`${styles.baseButton} ${styles.loginButton}`}>
              {t('Log In')}
            </button>
          </div>
        </div>
      </section>

      <section id="features" className={styles.features}>
        <h2>{t('Features')}</h2>
        <div className={styles.featureList}>
          <div className={styles.feature}>
            <FaCamera className={styles.featureIcon} />
            <h3>{t('Automatic Listing Creation')}</h3>
            <p>{t('Simply upload your property images, and our AI will create a complete listing for you, saving you time and effort.')}</p>
          </div>
          <div className={styles.feature}>
            <FaMapMarkedAlt className={styles.featureIcon} />
            <h3>{t('Surroundings Information')}</h3>
            <p>{t('Automatically gather and include information about local leisure, schools, and public transportation in ads.')}</p>
          </div>
          <div className={styles.feature}>
            <FaGlobe className={styles.featureIcon} />
            <h3>{t('Multilingual Support')}</h3>
            <p>{t('Create ads in multiple languages to reach a broader audience.')}</p>
          </div>
          <div className={styles.feature}>
            <FaImage className={styles.featureIcon} />
            <h3>{t('Image Enhancement')}</h3>
            <p>{t('Optimize and enhance your property images to attract more viewers.')}</p>
          </div>
          <div className={styles.feature}>
            <FaPalette className={styles.featureIcon} />
            <h3>{t('Customizable Templates')}</h3>
            <p>{t('Choose from various ad styles to suit your needs.')}</p>
          </div>
        </div>
      </section>

      <section id="pricing" className={styles.pricing}>
        <h2>{t('Pricing')}</h2>
        <div className={styles.pricingPlans}>
          <div className={styles.plan}>
            <h3>{t('Try for Free')}</h3>
            <p>{t('Try it for free! No credit card required.')}</p>
            <button onClick={handleLogin} className={`${styles.baseButton} ${styles.buyButton} ${styles.pricingButton}`}>
              {t('Try for Free')}
            </button>
          </div>
          <div className={styles.plan}>
            <h3>{t('Standard')}</h3>
            <p>{t('10 Ads for 10 CHF')}</p>
            <p>{t('1 CHF per Ad')}</p>
            <Link to="/purchase-credits" className={`${styles.baseButton} ${styles.buyButton} ${styles.pricingButton}`}>
              {t('Buy Now')}
            </Link>
          </div>
          <div className={styles.plan}>
            <h3>{t('Premium')}</h3>
            <p>{t('100 Ads for 80 CHF')}</p>
            <p>{t('0.80 CHF per Ad')}</p>
            <Link to="/purchase-credits" className={`${styles.baseButton} ${styles.buyButton} ${styles.pricingButton}`}>
              {t('Buy Now')}
            </Link>
          </div>
          <div className={styles.plan}>
            <h3>{t('Enterprise')}</h3>
            <p>{t('Custom plans for large companies')}</p>
            <a href="mailto:info@deepreturn.ch" className={`${styles.baseButton} ${styles.contactButton} ${styles.pricingButton}`}>
              {t('Contact Us')}
            </a>
            <button onClick={handleRedeemCode} className={`${styles.baseButton} ${styles.redeemButton} ${styles.pricingButton}`}>
              {t('Redeem Code')}
            </button>
          </div>
        </div>
        <SavingCalculator />
      </section>

      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <div className={styles.footerColumn}>
            <h4>{t('Contact Us')}</h4>
            <p>
              <a href="mailto:info@deepreturn.ch" className={styles.emailLink}>
                <FaEnvelope className={styles.icon} /> info@deepreturn.ch
              </a>
            </p>
          </div>

          <div className={styles.footerColumn}>
            <h4>{t('Quick Links')}</h4>
            <Link to="/privacy-policy">{t('Privacy Policy')}</Link>
            <button onClick={handleRedeemCode} className={styles.redeemButtonFooter}>
              {t('Redeem Code')}
            </button>
          </div>

          <div className={styles.footerColumn}>
            <h4>{t('Follow Us')}</h4>
            <a
              href="https://www.linkedin.com/company/79366300/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialIcon}
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.instagram.com/deep_return/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialIcon}
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>&copy; {new Date().getFullYear()} DeepReturn GmbH. {t('All rights reserved')}.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
