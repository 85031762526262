import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Modal from 'react-modal';
import Advertisement from './Advertisement';
import styles from './AdForm.module.css';
import debounce from 'lodash/debounce';
import { useAuth0 } from '@auth0/auth0-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaMapMarkerAlt } from 'react-icons/fa';

Modal.setAppElement('#root');

function AdForm({ updateCredits }) {
    const { t, i18n } = useTranslation();
    const { user, isAuthenticated } = useAuth0();

    const [adStyle, setAdStyle] = useState('minimalist');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [area, setArea] = useState('');
    const [rooms, setRooms] = useState(''); // New state for number of rooms
    const [availableFrom, setAvailableFrom] = useState('');
    const [priceNet, setPriceNet] = useState('');
    const [priceGross, setPriceGross] = useState('');
    const [extraCosts, setExtraCosts] = useState('');
    const [floor, setFloor] = useState('');
    const [adType, setAdType] = useState('');
    const [images, setImages] = useState([]);
    const [generatedAd, setGeneratedAd] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [additionalInfoModalIsOpen, setAdditionalInfoModalIsOpen] = useState(false);
    const [viewingAppointmentsModalIsOpen, setViewingAppointmentsModalIsOpen] = useState(false);
    const [address, setAddress] = useState('');
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [showAddressInAd, setShowAddressInAd] = useState(true);
    const [rentalUse, setRentalUse] = useState('');
    const [mapScreenshot, setMapScreenshot] = useState(null);
    const [priceOnRequest, setPriceOnRequest] = useState(false);
    const [credits, setCredits] = useState(0);
    const [adLanguage, setAdLanguage] = useState(i18n.language);
    const [appointments, setAppointments] = useState([]);
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [missingFields, setMissingFields] = useState([]);

    const adContentRef = useRef();
    const suggestionsRef = useRef();

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    useEffect(() => {
        if (isAuthenticated) {
            axios.get(`${apiUrl}/get-credits?user_id=${user.sub}`)
                .then(response => {
                    setCredits(response.data.credits);
                })
                .catch(error => {
                    console.error('Error fetching credits', error);
                });
        }
    }, [isAuthenticated, user, apiUrl]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setAddressSuggestions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const openAdditionalInfoModal = () => setAdditionalInfoModalIsOpen(true);
    const closeAdditionalInfoModal = () => setAdditionalInfoModalIsOpen(false);
    const openViewingAppointmentsModal = () => setViewingAppointmentsModalIsOpen(true);
    const closeViewingAppointmentsModal = () => setViewingAppointmentsModalIsOpen(false);

    const fetchAddressSuggestions = async (query) => {
        try {
            const response = await axios.get(`${apiUrl}/address-suggestions?query=${query}`);
            setAddressSuggestions(response.data);
        } catch (error) {
            console.error('Error fetching address suggestions:', error);
        }
    };

    const debouncedFetchAddressSuggestions = useCallback(debounce(fetchAddressSuggestions, 300), [apiUrl]);

    const handleAddressChange = (e) => {
        const newAddress = e.target.value;
        setAddress(newAddress);
        if (newAddress.length >= 5) {
            debouncedFetchAddressSuggestions(newAddress);
        } else {
            setAddressSuggestions([]);
        }
    };

    const handleAddressBlur = () => {
        if (address) {
            debouncedFetchMapScreenshot(address);
        }
    };

    const fetchMapScreenshot = async (address) => {
        try {
            const response = await axios.post(`${apiUrl}/get_map_screenshot`, { address });
            if (response.data.map_screenshot) {
                setMapScreenshot(response.data.map_screenshot);
            }
        } catch (error) {
            console.error('Error fetching map screenshot:', error);
        }
    };

    const debouncedFetchMapScreenshot = useCallback(debounce(fetchMapScreenshot, 1000), [apiUrl]);

    const handleAddAppointment = () => {
        if (appointmentDate) {
            const newAppointment = appointmentDate.toLocaleString(i18n.language, { dateStyle: 'short', timeStyle: 'short' });
            setAppointments([...appointments, newAppointment]);
            setAppointmentDate(null);
        }
    };

    const handleRemoveAppointment = (index) => {
        const newAppointments = appointments.filter((_, i) => i !== index);
        setAppointments(newAppointments);
    };

    const validateForm = () => {
        const requiredFields = ['area', 'rooms', 'address'];
        const missingFields = [];

        if (!area) missingFields.push(t('Area (sqm)'));
        if (!rooms) missingFields.push(t('Number of rooms'));
        if (!address) missingFields.push(t('Address'));

        setMissingFields(missingFields);
        return missingFields.length === 0;
    };

const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
        alert(t('Please fill in all required fields: ') + missingFields.join(', '));
        return;
    }

    if (credits <= 0) {
        alert(t('Not enough credits to generate an ad.'));
        return;
    }

    setLoading(true);

    // Ensure rentalUse and adType are set to the correct language-specific values
    const translatedRentalUse = rentalUse || t('Apartment');  // Default to 'Apartment' in the selected language
    const translatedAdType = adType || t('Rent');  // Default to 'Rent' in the selected language

    const formData = {
        adStyle,
        additionalInfo,
        area,
        rooms,
        availableFrom: availableFrom || t('Available from now'),
        priceNet: priceOnRequest ? t('Price on request') : priceNet,
        priceGross,
        extraCosts,
        floor,
        adType: translatedAdType,  // Pass translated adType
        address,
        rentalUse: translatedRentalUse,  // Pass translated rentalUse
        showAddressInAd,
        language: adLanguage,
        appointments,
    };

    const imagesBase64 = await Promise.all(images.slice(0, 9).map(file => encodeImage(file)));
    if (mapScreenshot) {
        imagesBase64.push(mapScreenshot);
    }

    Object.keys(formData).forEach(key => {
        if (formData[key] === '' || formData[key] == null) {
            delete formData[key];
        }
    });

    const payload = {
        ...formData,
        user_id: user.sub,  // Pass user_id to backend
        images: imagesBase64,
    };

    try {
        const response = await axios.post(`${apiUrl}/upload`, payload);
        setGeneratedAd(response.data.ad_content);
        setLoading(false);
        updateCredits(response.data.credits);  // Update credits with the new value
    } catch (error) {
        console.error('Error submitting ad:', error);
        setLoading(false);
    }
};

    const encodeImage = (imageFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(imageFile);
        });
    };

    const copyToClipboard = (event) => {
        event.preventDefault();
        if (adContentRef.current) {
            const range = document.createRange();
            range.selectNode(adContentRef.current);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            try {
                document.execCommand('copy');
                alert(t('Ad copied to clipboard!'));
            } catch (err) {
                console.error('Error copying to clipboard: ', err);
            }
            window.getSelection().removeAllRanges();
        }
    };

    const renderLabel = (labelText, isRequired) => (
        <label className={isRequired ? styles.requiredLabel : ''}>{labelText}</label>
    );

    return (
        <form onSubmit={handleSubmit} className={styles.adForm}>
            <div className={styles.formSection}>
                <h2>{t('Ad Style')}</h2>
                <div className={styles.formGroup}>
                    <label htmlFor="ad-style" className="label-inline">{t('Ad Style')}:</label>
                    <select id="ad-style" value={adStyle} onChange={e => setAdStyle(e.target.value)} required>
                        <option value="">{t('Select one')}</option>
                        <option value="narrative">{t('Narrative')}</option>
                        <option value="factual">{t('Factual')}</option>
                        <option value="minimalist">{t('Minimalist')}</option>
                    </select>
                    <button type="button" className={styles.infoButton} onClick={openModal}>i</button>
                </div>
            </div>

            <div className={styles.formSection}>
                <h2>
                    {t('Additional Info')}
                    <button type="button" className={styles.infoButton} onClick={openAdditionalInfoModal}>i</button>
                </h2>
                <div className={styles.formGroup}>
                    {renderLabel(t('Area (sqm)'), true)}
                    <input type="number" id="area" value={area} onChange={e => setArea(e.target.value)} placeholder={t('Enter area in sqm')} required />
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Number of rooms'), true)} {/* New field for number of rooms */}
                    <input type="number" id="rooms" value={rooms} onChange={e => setRooms(e.target.value)} placeholder={t('Enter number of rooms')} required />
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Available From'), false)}
                    <input type="text" id="available-from" value={availableFrom} onChange={e => setAvailableFrom(e.target.value)} placeholder={t('Enter Date or leave blank if available from now')} />
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Type'), false)}
                    <select id="ad-type" value={adType} onChange={e => setAdType(e.target.value)}>
                        <option value="Miete">{t('Rent')}</option>
                        <option value="Kaufen">{t('Buy')}</option>
                    </select>
                </div>
                {adType === 'Miete' ? (
                    <>
                        <div className={styles.formGroup}>
                            {renderLabel(t('Rent Price Net (CHF)'), false)}
                            <input type="number" id="price-net" value={priceNet} onChange={e => setPriceNet(e.target.value)} placeholder={t('Net rent price in CHF')} disabled={priceOnRequest} />
                        </div>
                        <div className={styles.formGroup}>
                            {renderLabel(t('Rent Price Gross (CHF)'), false)}
                            <input type="number" id="price-gross" value={priceGross} onChange={e => setPriceGross(e.target.value)} placeholder={t('Gross rent price in CHF')} disabled={priceOnRequest} />
                        </div>
                        <div className={styles.formGroup}>
                            {renderLabel(t('Extra Costs (CHF)'), false)}
                            <input type="number" id="extra-costs" value={extraCosts} onChange={e => setExtraCosts(e.target.value)} placeholder={t('Extra costs in CHF')} disabled={priceOnRequest} />
                        </div>
                    </>
                ) : (
                    <div className={styles.formGroup}>
                        {renderLabel(t('Buy Price (CHF)'), false)}
                        <input type="number" id="price" value={priceNet} onChange={e => setPriceNet(e.target.value)} placeholder={t('Buy price in CHF')} disabled={priceOnRequest} />
                    </div>
                )}
                <div className={styles.checkboxGroup}>
                    <input
                        type="checkbox"
                        id="price-on-request"
                        checked={priceOnRequest}
                        onChange={e => setPriceOnRequest(e.target.checked)}
                    />
                    <label htmlFor="price-on-request">{t('Price on Request')}</label>
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Floor'), false)}
                    <input type="number" id="floor" value={floor} onChange={e => setFloor(e.target.value)} placeholder={t('Floor number')} />
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Address'), true)}
                    <input
                        type="text"
                        id="address"
                        value={address}
                        onChange={handleAddressChange}
                        onBlur={handleAddressBlur}
                        placeholder={t('Enter address')}
                        required
                    />
                    {addressSuggestions.length > 0 && (
                        <ul className={styles.suggestions} ref={suggestionsRef}>
                            {addressSuggestions.map(suggestion => (
                                <li key={suggestion.place_id} onClick={() => setAddress(suggestion.description)}>
                                    <FaMapMarkerAlt className={styles.suggestionIcon} /> {suggestion.description}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className={styles.checkboxGroup}>
                    <input
                        type="checkbox"
                        id="show-address-in-ad"
                        checked={showAddressInAd}
                        onChange={e => setShowAddressInAd(e.target.checked)}
                    />
                    <label htmlFor="show-address-in-ad">{t('Show address in ad')}</label>
                </div>
                {mapScreenshot && (
                    <div className={styles.mapContainer}>
                        <img src={`data:image/jpeg;base64,${mapScreenshot}`} alt="Map Screenshot" />
                        <div className={styles.screenshotInfoLabel}>{t('This screenshot will help the AI to make the ad')}</div>
                    </div>
                )}
                <div className={styles.formGroup}>
                    {renderLabel(t('Rental Use'), false)}
                    <select id="rental-use" value={rentalUse} onChange={e => setRentalUse(e.target.value)} required>
                        <option value="Wohnung">{t('Apartment')}</option>
                        <option value="Einfamilienhaus">{t('Single Family House')}</option>
                        <option value="Mehrfamilienhaus">{t('Multi Family House')}</option>
                        <option value="Doppelhaus">{t('Duplex')}</option>
                        <option value="Laden">{t('Store')}</option>
                        <option value="Gewerbefläche">{t('Commercial Space')}</option>
                        <option value="Parkplatz">{t('Parking Spot')}</option>
                    </select>
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Ad Language'), false)}
                    <select id="ad-language" value={adLanguage} onChange={e => setAdLanguage(e.target.value)}>
                        <option value="en">{t('English')}</option>
                        <option value="de">{t('German')}</option>
                        <option value="fr">{t('French')}</option>
                    </select>
                </div>
                <div className={styles.formGroup}>
                    {renderLabel(t('Enter additional information'), false)}
                    <input
                        type="text"
                        id="additional-info"
                        value={additionalInfo}
                        onChange={e => setAdditionalInfo(e.target.value)}
                        placeholder={t('e.g. First occupancy, still being renovated.')}
                    />
                </div>
                <div className={styles.formSection}>
                    <h2>{t('Viewing Appointments')} <button type="button" className={styles.infoButton} onClick={openViewingAppointmentsModal}>i</button></h2>
                    <div className={styles.formGroup}>
                        <label htmlFor="appointment-date">{t('Date and Time')}:</label>
                        <div className={styles.datePickerContainer}>
                            <DatePicker
                                selected={appointmentDate}
                                onChange={date => setAppointmentDate(date)}
                                dateFormat="dd/MM/yyyy HH:mm"
                                placeholderText={t('Select date and time')}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption={t('Time')}
                            />
                            {appointmentDate && (
                                <button type="button" className={styles.smallButton} onClick={handleAddAppointment}>
                                    {t('Add Appointment')}
                                </button>
                            )}
                        </div>
                    </div>
                    <ul className={styles.appointmentList}>
                        {appointments.map((appt, index) => (
                            <li key={index}>
                                <span className={styles.appointmentDetails}>{t('Viewing Appointment')} {index + 1}: {appt}</span>
                                <button type="button" onClick={() => handleRemoveAppointment(index)}>❌</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <button type="submit" className={`${styles.button} ${styles.generateAd}`}>{t('Generate Ad')}</button>

            {loading && <div className={styles.spinner}></div>}

            {generatedAd && (
                <div>
                    <h2>{t('Generated Ad')}</h2>
                    <div ref={adContentRef}>
                        <Advertisement adContent={generatedAd} />
                    </div>
                    <button onClick={copyToClipboard} className={styles.copyButton}>{t('Copy Ad')}</button>
                </div>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={t('Ad Style Explanations')}
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
            >
                <button onClick={closeModal} className={styles.closeButton}>×</button>
                <h2>{t('Ad Style Explanations')}</h2>
                <p><strong>{t('Narrative')}:</strong> {t('Narrative style focuses on telling a story about the property, highlighting its unique features and history.')}</p>
                <p><strong>{t('Factual')}:</strong> {t('Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.')}</p>
                <p><strong>{t('Minimalist')}:</strong> {t('Minimalist style uses concise language and a clean layout to present essential information about the property.')}</p>
            </Modal>

            <Modal
                isOpen={additionalInfoModalIsOpen}
                onRequestClose={closeAdditionalInfoModal}
                contentLabel={t('Additional Info Explanations')}
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
            >
                <button onClick={closeAdditionalInfoModal} className={styles.closeButton}>×</button>
                <h2>{t('Additional Info Explanations')}</h2>
                <p>{t('Our AI can create housing ads directly from images, but it\'s helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).')}</p>
            </Modal>

            <Modal
                isOpen={viewingAppointmentsModalIsOpen}
                onRequestClose={closeViewingAppointmentsModal}
                contentLabel={t('Viewing Appointments Information')}
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
            >
                <button onClick={closeViewingAppointmentsModal} className={styles.closeButton}>×</button>
                <h2>{t('Viewing Appointments Information')}</h2>
                <p>{t('You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.')}</p>
            </Modal>
        </form>
    );
}

export default AdForm;
