import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';  // Import rehypeRaw plugin
import './Advertisement.css';

function Advertisement({ adContent }) {
  const { t } = useTranslation();

  // Replace all instances of "ß" with "ss"
  const updatedAdContent = adContent.replace(/ß/g, 'ss');

  return (
    <div className="generated-ad">
      {/* Enable raw HTML rendering with rehypeRaw */}
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {updatedAdContent}
      </ReactMarkdown>
    </div>
  );
}

export default Advertisement;
