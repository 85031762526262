import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageUpload from '../../pages/AdCreation/ImageUpload';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import AdFormRedeem from '../../pages/AdCreation/AdFormRedeem';
import Advertisement from '../../pages/AdCreation/Advertisement';
import Footer from '../../components/Footer/Footer';
import deepreturnLogo from '../../assets/images/deepreturn.png';
import './TestPage.css';
import { useTranslation } from 'react-i18next';
import CheckoutFormRedeem from '../../components/CheckoutFormRedeem';

// Load your Stripe publishable key
const stripePromise = loadStripe('your-publishable-key-here');

const TestPage = () => {
  const [images, setImages] = useState([]);
  const [generatedAd, setGeneratedAd] = useState(null);
  const [credits, setCredits] = useState(0);
  const { t } = useTranslation();

  const sessionToken = localStorage.getItem('session_token');
  const redeemCode = localStorage.getItem('redeem_code');

  // Use dynamic API URL
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  useEffect(() => {
    const fetchCredits = async () => {
      if (sessionToken && redeemCode) {
        try {
          const response = await axios.post(`${apiUrl}/redeem-code`, { code: redeemCode });
          if (response.data.success) {
            setCredits(response.data.credits);
            localStorage.setItem('session_token', response.data.session_token);
            localStorage.setItem('redeem_code', response.data.code);
          } else {
            console.error('Error fetching credits', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching credits', error);
        }
      } else {
        console.error('No session token or redeem code found');
      }
    };

    fetchCredits();
  }, [sessionToken, redeemCode, apiUrl]);

  const updateCredits = (newCredits) => {
    setCredits(newCredits);
    localStorage.setItem('credits', newCredits);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <header className="header">
          <div className="logo-section">
            <div className="title">PicToAd Partner Zugang</div>
          </div>
          <div className="right-section">
            <a href="http://www.deepreturn.ch" target="_blank" rel="noopener noreferrer">
              <img src={deepreturnLogo} alt="DeepReturn Logo" className="logo" />
            </a>
          </div>
        </header>
        <div className="header-controls-container">
          <div className="header-controls">
            <div className="control-group">
              <div className="language-switcher">
                <LanguageSwitcher />
              </div>
              {redeemCode && (
                <div className="credits-info">
                  {t('Credits')}: {credits}
                </div>
              )}
            </div>
            <div className="control-group">
              <Link to="/purchase-credits" className="buy-credits-button">
                {t('Buy Credits')}
              </Link>
            </div>
          </div>
        </div>
        <ImageUpload setImages={setImages} />
        <AdFormRedeem setGeneratedAd={setGeneratedAd} updateCredits={updateCredits} redeemCode={redeemCode} />
        {generatedAd && <Advertisement adContent={generatedAd} />}
        <Footer />
      </div>
    </Elements>
  );
};

export default TestPage;
