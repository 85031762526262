import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageUpload from '../../pages/AdCreation/ImageUpload';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import AdForm from '../../pages/AdCreation/AdForm';
import Advertisement from '../../pages/AdCreation/Advertisement';
import Footer from '../../components/Footer/Footer';
import deepreturnLogo from '../../assets/images/deepreturn.png';
import './HomePage.css';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const [images, setImages] = useState([]);
  const [generatedAd, setGeneratedAd] = useState(null);
  const [credits, setCredits] = useState(0);
  const { t } = useTranslation();

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001'; // Use dynamic API URL

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const storedUserId = localStorage.getItem('user_id');
        console.log("Stored User ID:", storedUserId); // Debugging log
        let userId = isAuthenticated ? user.sub : storedUserId;
        console.log("User ID being used:", userId); // Debugging log
        if (userId) {
          const response = await axios.get(`${apiUrl}/get-credits?user_id=${userId}`); // Use dynamic API URL
          console.log("Response from /get-credits:", response.data); // Debugging log
          setCredits(response.data.credits);
        } else {
          console.log("No user ID found");
        }
      } catch (error) {
        console.error('Error fetching credits', error);
      }
    };

    fetchCredits();
  }, [isAuthenticated, user, apiUrl]);

  const updateCredits = (newCredits) => {
    setCredits(newCredits);
    console.log("Updated credits:", newCredits); // Debugging log
  };

  return (
    <div className="App">
      <header className="header">
        <div className="logo-section">
          <div className="title">ImmoAI</div>
        </div>
        <div className="right-section">
          <a href="http://www.deepreturn.ch" target="_blank" rel="noopener noreferrer">
            <img src={deepreturnLogo} alt="DeepReturn Logo" className="logo" />
          </a>
        </div>
      </header>
      <div className="header-controls-container">
        <div className="header-controls">
          <div className="control-group">
            <div className="language-switcher">
              <LanguageSwitcher />
            </div>
            {(isAuthenticated || localStorage.getItem('user_id')) && (
              <div className="credits-info">
                {t('Credits')}: {credits}
              </div>
            )}
          </div>
          <div className="control-group">
            {isAuthenticated && (
              <>
                <button className="logout-button" onClick={() => logout({ returnTo: window.location.origin })}>
                  {t('Log Out')}
                </button>
                <Link to="/purchase-credits" className="buy-credits-button">
                  {t('Buy Credits')}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <ImageUpload setImages={setImages} />
      <AdForm setGeneratedAd={setGeneratedAd} updateCredits={updateCredits} />
      {generatedAd && <Advertisement adContent={generatedAd} />}
      <Footer />
    </div>
  );
};

export default HomePage;
