import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './pages/HomePage/HomePage';
import TestPage from './pages/TestPage/TestPage'; // Add this line
import Login from './pages/LoginPage/Login';
import Success from './pages/SuccessPage/Success';
import PurchaseCredits from './pages/PurchaseCredits/PurchaseCredits';
import Spinner from './components/Spinner/Spinner';
import LandingPage from './pages/LandingPage/LandingPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import RedeemCode from './pages/RedeemCode/RedeemCode'; // Import the RedeemCode component

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    // Redirect to /app if authenticated and trying to access the root
    if (isAuthenticated && location.pathname === '/') {
      window.history.replaceState({}, '', '/app');
    }
  }, [isAuthenticated, location.pathname]);

  if (isLoading) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/app" /> : <LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/app" element={isAuthenticated ? <HomePage /> : <Navigate to="/login" />} />
      <Route path="/test-app" element={<TestPage />} /> {/* Add this route */}
      <Route path="/purchase-credits" element={isAuthenticated ? <PurchaseCredits /> : <Navigate to="/login" />} />
      <Route path="/success" element={isAuthenticated ? <Success /> : <Navigate to="/login" />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/redeem" element={<RedeemCode />} /> {/* Add this route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
