import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';
import { useTranslation } from 'react-i18next';
import styles from './SavingCalculator.module.css';

const Slider = ({ label, value, onChange, step, min, max }) => {
  return (
    <div className={styles.sliderContainer}>
      <label className={styles.sliderLabel}>{label}</label>
      <Range
        step={step}
        min={min}
        max={max}
        values={[value]}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div {...props} className={styles.sliderTrack}>
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className={styles.sliderThumb} />
        )}
      />
      <div className={styles.sliderValue}>{value}</div>
    </div>
  );
};

const calculateMoneySaved = (files, salary, minutes) => {
  const minutesPerYear = files * minutes * 12;
  const minutesPerWorker = 45 * 47 * 60;
  const costPerMinute = salary / minutesPerWorker;
  return Math.round(minutesPerYear * costPerMinute);
};

const SavingCalculator = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState(12);
  const [salary, setSalary] = useState(120000);
  const [minutes, setMinutes] = useState(20);
  const [moneySaved, setMoneySaved] = useState(0);

  useEffect(() => {
    setMoneySaved(calculateMoneySaved(files, salary, minutes));
  }, [files, salary, minutes]);

  return (
    <div className={styles.calculator}>
      <h3>{t('Calculate Your Savings')}</h3>
      <Slider
        label={t('Ads per month')}
        value={files}
        onChange={([value]) => setFiles(value)}
        step={1}
        min={1}
        max={100}
      />
      <Slider
        label={t('Employee Gross Annual Salary')}
        value={salary}
        onChange={([value]) => setSalary(value)}
        step={10000}
        min={50000}
        max={200000}
      />
      <Slider
        label={t('Minutes per ad')}
        value={minutes}
        onChange={([value]) => setMinutes(value)}
        step={5}
        min={1}
        max={60}
      />
      <div className={styles.savingsResult}>
        {t('You can save')} {moneySaved.toLocaleString()} CHF {t('per year')}!
      </div>
    </div>
  );
};

export default SavingCalculator;
