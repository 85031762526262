import React, { useState } from 'react';
import Modal from 'react-modal';
import deepreturnLogo from '../../assets/images/deepreturn.png';  // Adjusted path for the logo
import { useTranslation } from 'react-i18next';
import './Footer.css';  // Import Footer CSS

Modal.setAppElement('#root');  // Set the app element for accessibility

function Footer() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <img src={deepreturnLogo} alt="DeepReturn Logo" className="footer-logo" />
          <div className="footer-info">
            <a href="#!" onClick={openModal}>{t('Privacy Policy')}</a>
            <span>{t('All rights reserved')}</span>
            <a href="mailto:info@deepreturn.ch">info@deepreturn.ch</a>
            <span>Tel: +41 76 758 96 80</span>
            <div className="linkedin-icon">
              <a aria-label="linkedin" href="https://www.linkedin.com/company/79366300/" target="_blank" rel="noreferrer">
                <svg className="fill-current text-gray-800 hover:text-primary" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={t('Privacy Policy')}
        className="Modal"
        overlayClassName="Overlay"
      >
        <button className="close-button" onClick={closeModal}>×</button>
        <h2>{t('Privacy Policy')}</h2>
        <p>{t('Privacy Policy Content')}</p>
      </Modal>
    </>
  );
}

export default Footer;
