import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next.
  .use(LanguageDetector) // Passes a language detector.
  .init({
    resources: {
      "en": {
        "translation": {
          // General
          "Log In": "Log In",
          "Sign up here": "Sign up here",
          "Don't have an account?": "Don't have an account?",
          "Privacy Policy": "Privacy Policy",
          "All rights reserved": "All rights reserved",
          "Back to Main Page": "Back to Main Page",
          
          // Landing Page
          "Welcome to ImmoAI": "Welcome to ImmoAI",
          "Your AI-Powered Real Estate Ad Creator": "Your AI-Powered Real Estate Ad Creator",
          "Create Stunning Real Estate Listings Instantly": "Create Stunning Real Estate Listings Instantly",
          "Upload your photos – our AI generates listings and gathers surrounding information automatically": "Upload your photos – our AI generates listings and gathers surrounding information automatically",
          "Create Your Listing Now": "Create Your Listing Now",
          "Home": "Home",
          "Features": "Features",
          "Pricing": "Pricing",
          "Try it out": "Try it out",
          "AI-Powered Ad Generation": "AI-Powered Ad Generation",
          "Generate compelling real estate ads with the help of advanced AI technology.": "Generate compelling real estate ads with the help of advanced AI technology.",
          "Multilingual Support": "Multilingual Support",
          "Create ads in multiple languages to reach a broader audience.": "Create ads in multiple languages to reach a broader audience.",
          "Image Enhancement": "Image Enhancement",
          "Optimize and enhance your property images to attract more viewers.": "Optimize and enhance your property images to attract more viewers.",
          "Customizable Templates": "Customizable Templates",
          "Choose from various ad styles to suit your needs.": "Choose from various ad styles to suit your needs.",
          "Surroundings Information": "Surroundings Information",
          "Automatically gather and include information about local leisure, schools, and public transportation in ads.": "Automatically gather and include information about local leisure, schools, and public transportation in ads.",
          
          // Redeem Code
          "Redeem Your Code": "Redeem Your Code",
          "Enter your code": "Enter your code",
          "Redeem": "Redeem",
          "Invalid or already used code": "Invalid or already used code",

          // Account and Payment
          "Payment Successful!": "Payment Successful!",
          "Your credits have been added to your account.": "Your credits have been added to your account.",
          "Credits": "Credits",
          "Buy Credits": "Buy Credits",
          "Purchase Credits": "Purchase Credits",
          "Select Credits": "Select Credits",
          "10 Credits for 10 CHF": "10 Credits for 10 CHF",
          "100 Credits for 80 CHF": "100 Credits for 80 CHF",
          "Pay 10 CHF": "Pay 10 CHF",
          "Pay 80 CHF": "Pay 80 CHF",
          "Standard": "Standard",
          "Premium": "Premium",
          "Enterprise": "Enterprise",
          "Custom plans for large companies": "Custom plans for large companies",
          "Contact Us": "Contact Us",
          "Buy Now": "Buy Now",

          // Ad Creation
          "Submit Ad": "Generate Ad",
          "Additional Info": "Additional Info",
          "Ad Style": "Ad Style",
          "Narrative": "Narrative",
          "Factual": "Factual",
          "Minimalist": "Minimalist",
          "Enter additional information": "Enter additional information",
          "Select one": "Select one",
          "Area (sqm)": "Area (sqm)",
          "Enter area in sqm": "Enter area in sqm",
          "Available From": "Available From",
          "Rent Price Net (CHF)": "Rent Price Net (CHF)",
          "Net rent price in CHF": "Net rent price in CHF",
          "Rent Price Gross (CHF)": "Rent Price Gross (CHF)",
          "Gross rent price in CHF": "Gross rent price in CHF",
          "Buy Price Net (CHF)": "Buy Price Net (CHF)",
          "Net buy price in CHF": "Net buy price in CHF",
          "Buy Price Gross (CHF)": "Buy Price Gross (CHF)",
          "Gross buy price in CHF": "Gross buy price in CHF",
          "Floor": "Floor",
          "Floor number": "Floor number",
          "Type": "Type",
          "Rent": "Rent",
          "Buy": "Buy",
          "Address": "Address",
          "Enter address": "Enter address",
          "Show address in ad": "Show address in ad",
          "Rental Use": "Rental Use",
          "Apartment": "Apartment",
          "Single Family House": "Single Family House",
          "Multi Family House": "Multi Family House",
          "Duplex": "Duplex",
          "Store": "Store",
          "Commercial Space": "Commercial Space",
          "Parking Spot": "Parking Spot",
          "e.g. First occupancy, still being renovated.": "e.g. First occupancy, still being renovated.",
          "Ad Style Explanations": "Ad Style Explanations",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Narrative style focuses on telling a story about the property, highlighting its unique features and history.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Minimalist style uses concise language and a clean layout to present essential information about the property.",
          "Additional Info Explanations": "Additional Info Explanations",
          "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).": "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).",
          "Unsupported format": "Unsupported format",
          "Supported formats": "Supported formats",

          
          // Viewing Appointments
          "Viewing Appointments": "Viewing Appointments (optional)",
          "Add viewing appointments for the ad": "You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.",
          "Viewing Appointment 1: DATE and TIME": "Viewing Appointment 1: DATE and TIME",
          "Available from now": "Available from now",
          "Enter Date or leave blank if available from now": "Enter Date or leave blank if available from now",
          "Date and Time": "Date and Time",
          "Select date and time": "Select date and time",
          "Time": "Time",
          "Add Appointment": "Add Appointment",
          
          // Miscellaneous
          "Please fill in all required fields.": "Please fill in all required fields.",
          "Ad copied to clipboard!": "Ad copied to clipboard!",
          "Drag and drop your images here or click to select images": "Drag and drop your images here or click to select images",
          "Suggested images:": "Suggested images:",
          "Indoor pictures": "Indoor pictures",
          "Pictures of the surroundings": "Pictures of the surroundings",
          "Floor plan (Grundrissplan) if possible": "Floor plan if possible",
          "This screenshot will help the AI to make the ad": "This screenshot will help the AI to make the ad",
          "Extra Costs (CHF)": "Extra Costs (CHF)",
          "Extra costs in CHF": "Extra costs in CHF",
          "Price on Request": "Price on Request",
          "Ad Language": "Ad Language",
          "English": "English",
          "German": "German",
          "French": "French",
          "Available from now": "Available from now",
          "Per Ad": "Per Ad",
          "Quick Links": "Quick Links",
          "Follow Us": "Follow Us",
          "Redeem Code": "Redeem Code",
          "Calculate Your Savings": "Calculate Your Savings",
          "Ads per month": "Ads per month",
          "Employee Gross Annual Salary": "Employee Gross Annual Salary",
          "Minutes per ad": "Minutes per ad",
          "You can save": "You can save",
          "per year": "per year",
          "Welcome to ImmoAI": "Willkommen bei ImmoAI",
          "Your AI-powered real estate ad creator.": "Ihr KI-gestützter Immobilienanzeigen-Generator.",
          "Log In": "Anmelden",
          "Don't have an account?": "Haben Sie kein Konto?",
          "Sign up here": "Hier registrieren",
          "ImmoAI by DeepReturn": "ImmoAI von DeepReturn",
          "Number of rooms": "Number of rooms",
          "Enter number of rooms": "Enter number of rooms"
      }
        },
      "de": {
        "translation": {
          // General
          "Log In": "Anmelden",
          "Sign up here": "Hier registrieren",
          "Don't have an account?": "Haben Sie kein Konto?",
          "Privacy Policy": "Datenschutzrichtlinie",
          "All rights reserved": "Alle Rechte vorbehalten",
          "Back to Main Page": "Zur Hauptseite",
          
          // Landing Page
          "Welcome to ImmoAI": "Willkommen bei ImmoAI",
          "Your AI-Powered Real Estate Ad Creator": "Ihr KI-gestützter Immobilienanzeigen-Generator",
          "Create Stunning Real Estate Listings Instantly": "Erstellen Sie beeindruckende Immobilienanzeigen sofort",
          "Upload your photos – our AI generates listings and gathers surrounding information automatically": "Laden Sie Ihre Fotos hoch – unsere KI erstellt automatisch Anzeigen und sammelt Umgebungsinformationen",
          "Create Your Listing Now": "Erstellen Sie jetzt Ihre Anzeige",
          "Home": "Startseite",
          "Features": "Funktionen",
          "Pricing": "Preise",
          "Try it out": "Jetzt ausprobieren",
          "AI-Powered Ad Generation": "KI-gestützte Anzeigenerstellung",
          "Generate compelling real estate ads with the help of advanced AI technology.": "Erstellen Sie überzeugende Immobilienanzeigen mit Hilfe fortschrittlicher KI-Technologie.",
          "Multilingual Support": "Mehrsprachige Unterstützung",
          "Create ads in multiple languages to reach a broader audience.": "Erstellen Sie Anzeigen in mehreren Sprachen, um ein breiteres Publikum zu erreichen.",
          "Image Enhancement": "Bildverbesserung",
          "Optimize and enhance your property images to attract more viewers.": "Optimieren und verbessern Sie Ihre Immobilienbilder, um mehr Betrachter anzuziehen.",
          "Customizable Templates": "Anpassbare Vorlagen",
          "Choose from various ad styles to suit your needs.": "Wählen Sie aus verschiedenen Anzeigestilen, die Ihren Bedürfnissen entsprechen.",
          "Surroundings Information": "Umgebungsinformationen",
          "Automatically gather and include information about local leisure, schools, and public transportation in ads.": "Automatisch Informationen über lokale Freizeitmöglichkeiten, Schulen und öffentliche Verkehrsmittel in Anzeigen aufnehmen.",
          
          // Redeem Code
          "Redeem Your Code": "Lösen Sie Ihren Code ein",
          "Enter your code": "Geben Sie Ihren Code ein",
          "Redeem": "Einlösen",
          "Invalid or already used code": "Ungültiger oder bereits verwendeter Code",

          // Account and Payment
          "Payment Successful!": "Zahlung erfolgreich!",
          "Your credits have been added to your account.": "Ihre Credits wurden Ihrem Konto gutgeschrieben.",
          "Credits": "Kredite",
          "Buy Credits": "Kredite kaufen",
          "Purchase Credits": "Credits kaufen",
          "Select Credits": "Credits auswählen",
          "10 Credits for 10 CHF": "10 Credits für 10 CHF",
          "100 Credits for 80 CHF": "100 Credits für 80 CHF",
          "Pay 10 CHF": "10 CHF bezahlen",
          "Pay 80 CHF": "80 CHF bezahlen",
          "Standard": "Standard",
          "Premium": "Premium",
          "Enterprise": "Unternehmen",
          "Custom plans for large companies": "Individuelle Pläne für große Unternehmen",
          "Contact Us": "Kontaktieren Sie uns",
          "Buy Now": "Jetzt kaufen",

          // Ad Creation
          "Submit Ad": "Anzeige generieren",
          "Additional Info": "Zusätzliche Infos (optional)",
          "Ad Style": "Anzeigenstil",
          "Narrative": "Erzählend",
          "Factual": "Faktisch",
          "Minimalist": "Minimalistisch",
          "Enter additional information": "Geben Sie zusätzliche Informationen ein",
          "Select one": "Wählen Sie",
          "Area (sqm)": "Fläche (qm)",
          "Enter area in sqm": "Geben Sie die Fläche in qm ein",
          "Available From": "Verfügbar ab",
          "Rent Price Net (CHF)": "Nettomietpreis (CHF)",
          "Net rent price in CHF": "Nettomietpreis in CHF",
          "Rent Price Gross (CHF)": "Bruttomietpreis (CHF)",
          "Gross rent price in CHF": "Bruttomietpreis in CHF",
          "Buy Price Net (CHF)": "Kaufpreis Netto (CHF)",
          "Net buy price in CHF": "Kaufpreis Netto in CHF",
          "Buy Price Gross (CHF)": "Kaufpreis Brutto (CHF)",
          "Gross buy price in CHF": "Kaufpreis Brutto in CHF",
          "Floor": "Etage",
          "Floor number": "Etagennummer",
          "Type": "Typ",
          "Rent": "Miete",
          "Buy": "Kaufen",
          "Address": "Adresse",
          "Enter address": "Geben Sie die Adresse ein",
          "Show address in ad": "Adresse in der Anzeige anzeigen",
          "Rental Use": "Nutzungsart",
          "Apartment": "Wohnung",
          "Single Family House": "Einfamilienhaus",
          "Multi Family House": "Mehrfamilienhaus",
          "Duplex": "Doppelhaus",
          "Store": "Laden",
          "Commercial Space": "Gewerbefläche",
          "Parking Spot": "Parkplatz",
          "e.g. First occupancy, still being renovated.": "z.B. Erstbezug, wird noch renoviert.",
          "Ad Style Explanations": "Erklärungen zum Anzeigenstil",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Der erzählende Stil konzentriert sich darauf, eine Geschichte über die Immobilie zu erzählen und ihre einzigartigen Merkmale und ihre Geschichte hervorzuheben.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Der faktische Stil liefert klare, detaillierte Informationen über die Immobilie und betont Daten und Einzelheiten.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Der minimalistische Stil verwendet eine prägnante Sprache und ein klares Layout, um wesentliche Informationen über die Immobilie zu präsentieren.",
          "Additional Info Explanations": "Erklärungen zu zusätzlichen Informationen",
          "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).": "Unsere KI kann Wohnungsanzeigen direkt aus Bildern erstellen, aber es ist hilfreich, hier Informationen auszufüllen, die die KI auf Bildern nicht sehen kann. Erforderliche Felder sind der Mietpreis (falls nicht auf Anfrage) und die Adresse (dies hilft der KI, die Umgebung zu überprüfen und Highlights in die Anzeige aufzunehmen).",
          "Unsupported format": "Nicht unterstütztes Format",
          "Supported formats": "Unterstützte Formate",

          
          // Viewing Appointments
          "Viewing Appointments": "Besichtigungstermine (optional)",
          "Add viewing appointments for the ad": "Sie können Besichtigungstermine hinzufügen, um sie in der Anzeige anzuzeigen. Diese sind optional, können aber potenziellen Mietern helfen, Besichtigungen bequemer zu planen.",
          "Viewing Appointment 1: DATE and TIME": "Besichtigungstermin 1: DATUM und ZEIT",
          "Available from now": "Ab sofort verfügbar",
          "Enter Date or leave blank if available from now": "Datum eingeben oder leer lassen, wenn ab sofort verfügbar",
          "Date and Time": "Datum und Uhrzeit",
          "Select date and time": "Datum und Uhrzeit auswählen",
          "Time": "Uhrzeit",
          "Add Appointment": "Termin hinzufügen",
          
          // Miscellaneous
          "Please fill in all required fields.": "Bitte füllen Sie alle erforderlichen Felder aus.",
          "Ad copied to clipboard!": "Anzeige in die Zwischenablage kopiert!",
          "Drag and drop your images here or click to select images": "Ziehen Sie Ihre Bilder hierher oder klicken Sie, um Dateien auszuwählen",
          "Suggested images:": "Vorgeschlagene Bilder:",
          "Indoor pictures": "Innenaufnahmen",
          "Pictures of the surroundings": "Bilder der Umgebung",
          "Floor plan (Grundrissplan) if possible": "Grundrissplan wenn möglich",
          "This screenshot will help the AI to make the ad": "Dieser Screenshot hilft der KI bei der Erstellung der Anzeige",
          "Extra Costs (CHF)": "Nebenkosten (CHF)",
          "Extra costs in CHF": "Nebenkosten in CHF",
          "Price on Request": "Preis auf Anfrage",
          "Ad Language": "Anzeigensprache",
          "English": "Englisch",
          "German": "Deutsch",
          "French": "Französisch",
          "Available from now": "Ab sofort verfügbar",
          "Per Ad": "Pro Anzeige",
          "Quick Links": "Schnellzugriff",
          "Follow Us": "Folgen Sie uns",
          "Redeem Code": "Code einlösen",
          "Calculate Your Savings": "Berechnen Sie Ihre Einsparungen",
          "Ads per month": "Anzeigen pro Monat",
          "Employee Gross Annual Salary": "Bruttojahresgehalt des Mitarbeiters",
          "Minutes per ad": "Minuten pro Anzeige",
          "You can save": "Sie können sparen",
          "per year": "pro Jahr",
          "Welcome to ImmoAI": "Willkommen bei ImmoAI",
          "Your AI-powered real estate ad creator.": "Ihr KI-gestützter Immobilienanzeigen-Generator.",
          "Log In": "Anmelden",
          "Don't have an account?": "Haben Sie kein Konto?",
          "Sign up here": "Hier registrieren",
          "ImmoAI by DeepReturn": "ImmoAI von DeepReturn",
          "Number of rooms": "Anzahl der Zimmer",
          "Enter number of rooms": "Geben Sie die Anzahl der Zimmer ein",
          "Viewing Appointments Information": "Informationen zu Besichtigungsterminen",
          "You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.": "Sie können Besichtigungstermine hinzufügen, um sie in der Anzeige anzuzeigen. Diese sind optional, können jedoch potenziellen Mietern helfen, Besichtigungen bequemer zu planen."
        }
      },
      "fr": {
        "translation": {
          // General
          "Log In": "Connexion",
          "Sign up here": "Inscrivez-vous ici",
          "Don't have an account?": "Vous n'avez pas de compte?",
          "Privacy Policy": "Politique de confidentialité",
          "All rights reserved": "Tous droits réservés",
          "Back to Main Page": "Retour à la page principale",
          
          // Landing Page
          "Welcome to ImmoAI": "Bienvenue chez ImmoAI",
          "Your AI-Powered Real Estate Ad Creator": "Votre générateur d'annonces immobilières propulsé par l'IA",
          "Create Stunning Real Estate Listings Instantly": "Créez des annonces immobilières impressionnantes instantanément",
          "Upload your photos – our AI generates listings and gathers surrounding information automatically": "Téléchargez vos photos – notre IA génère des annonces et recueille automatiquement des informations sur les environs",
          "Create Your Listing Now": "Créez votre annonce maintenant",
          "Home": "Accueil",
          "Features": "Caractéristiques",
          "Pricing": "Tarification",
          "Try it out": "Essayez-le",
          "AI-Powered Ad Generation": "Génération d'annonces assistée par l'IA",
          "Generate compelling real estate ads with the help of advanced AI technology.": "Générez des annonces immobilières convaincantes avec l'aide d'une technologie IA avancée.",
          "Multilingual Support": "Support multilingue",
          "Create ads in multiple languages to reach a broader audience.": "Créez des annonces en plusieurs langues pour atteindre un public plus large.",
          "Image Enhancement": "Amélioration d'image",
          "Optimize and enhance your property images to attract more viewers.": "Optimisez et améliorez vos images immobilières pour attirer plus de spectateurs.",
          "Customizable Templates": "Modèles personnalisables",
          "Choose from various ad styles to suit your needs.": "Choisissez parmi divers styles d'annonces pour répondre à vos besoins.",
          "Surroundings Information": "Informations sur les environs",
          "Automatically gather and include information about local leisure, schools, and public transportation in ads.": "Rassembler automatiquement des informations sur les loisirs locaux, les écoles et les transports publics dans les annonces.",
          
          // Redeem Code
          "Redeem Your Code": "Utilisez votre code",
          "Enter your code": "Entrez votre code",
          "Redeem": "Valider",
          "Invalid or already used code": "Code invalide ou déjà utilisé",

          // Account and Payment
          "Payment Successful!": "Paiement réussi!",
          "Your credits have been added to your account.": "Vos crédits ont été ajoutés à votre compte.",
          "Credits": "Crédits",
          "Buy Credits": "Acheter des crédits",
          "Purchase Credits": "Acheter des crédits",
          "Select Credits": "Sélectionner des crédits",
          "10 Credits for 10 CHF": "10 crédits pour 10 CHF",
          "100 Credits for 80 CHF": "100 crédits pour 80 CHF",
          "Pay 10 CHF": "Payer 10 CHF",
          "Pay 80 CHF": "Payer 80 CHF",
          "Standard": "Standard",
          "Premium": "Premium",
          "Enterprise": "Entreprise",
          "Custom plans for large companies": "Plans personnalisés pour les grandes entreprises",
          "Contact Us": "Contactez-nous",
          "Buy Now": "Acheter maintenant",

          // Ad Creation
          "Submit Ad": "Générer une annonce",
          "Additional Info": "Infos supplémentaires",
          "Ad Style": "Style de l'annonce",
          "Narrative": "Narratif",
          "Factual": "Factuel",
          "Minimalist": "Minimaliste",
          "Enter additional information": "Entrez des informations supplémentaires",
          "Select one": "Sélectionnez-en un",
          "Area (sqm)": "Surface (m²)",
          "Enter area in sqm": "Entrez la surface en m²",
          "Available From": "Disponible à partir de",
          "Rent Price Net (CHF)": "Loyer net (CHF)",
          "Net rent price in CHF": "Loyer net en CHF",
          "Rent Price Gross (CHF)": "Loyer brut (CHF)",
          "Gross rent price in CHF": "Loyer brut en CHF",
          "Buy Price Net (CHF)": "Prix d'achat net (CHF)",
          "Net buy price in CHF": "Prix d'achat net en CHF",
          "Buy Price Gross (CHF)": "Prix d'achat brut (CHF)",
          "Gross buy price in CHF": "Prix d'achat brut en CHF",
          "Floor": "Étage",
          "Floor number": "Numéro de l'étage",
          "Type": "Type",
          "Rent": "Louer",
          "Buy": "Acheter",
          "Address": "Adresse",
          "Enter address": "Entrez l'adresse",
          "Show address in ad": "Afficher l'adresse dans l'annonce",
          "Rental Use": "Utilisation locative",
          "Apartment": "Appartement",
          "Single Family House": "Maison individuelle",
          "Multi Family House": "Maison multifamiliale",
          "Duplex": "Duplex",
          "Store": "Magasin",
          "Commercial Space": "Espace commercial",
          "Parking Spot": "Place de parking",
          "e.g. First occupancy, still being renovated.": "par ex. Première occupation, encore en rénovation.",
          "Ad Style Explanations": "Explications sur le style de l'annonce",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Le style narratif se concentre sur le récit d'une histoire sur la propriété, en mettant en évidence ses caractéristiques uniques et son histoire.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Le style factuel fournit des informations claires et détaillées sur la propriété, en mettant l'accent sur les données et les spécificités.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Le style minimaliste utilise un langage concis et une mise en page épurée pour présenter les informations essentielles sur la propriété.",
          "Additional Info Explanations": "Explications sur les infos supplémentaires",
          "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).": "Notre IA peut créer des annonces immobilières directement à partir d'images, mais il est utile de remplir les informations ici que l'IA ne peut pas voir sur les images. Les champs obligatoires sont le prix du loyer (si ce n'est pas sur demande) et l'adresse (cela aide l'IA à vérifier les environs et à inclure des points forts dans l'annonce).",
          "Unsupported format": "Format non supporté",
          "Supported formats": "Formats pris en charge",

          
          // Viewing Appointments
          "Viewing Appointments": "Rendez-vous de visite (optionnel)",
          "Add viewing appointments for the ad": "Vous pouvez ajouter des rendez-vous de visite pour les afficher dans l'annonce. Ceux-ci sont optionnels mais peuvent aider les locataires potentiels à planifier des visites plus facilement.",
          "Viewing Appointment 1: DATE and TIME": "Rendez-vous de visite 1: DATE et HEURE",
          "Available from now": "Disponible dès maintenant",
          "Enter Date or leave blank if available from now": "Entrez la date ou laissez vide si disponible dès maintenant",
          "Date and Time": "Date et heure",
          "Select date and time": "Sélectionnez la date et l'heure",
          "Time": "Heure",
          "Add Appointment": "Ajouter un rendez-vous",
          
          // Miscellaneous
          "Please fill in all required fields.": "Veuillez remplir tous les champs obligatoires.",
          "Ad copied to clipboard!": "Annonce copiée dans le presse-papiers!",
          "Drag and drop your images here or click to select images": "Faites glisser et déposez vos images ici ou cliquez pour sélectionner des fichiers",
          "Suggested images:": "Images suggérées:",
          "Indoor pictures": "Photos d'intérieur",
          "Pictures of the surroundings": "Photos des environs",
          "Floor plan (Grundrissplan) if possible": "Plan d'étage si possible",
          "This screenshot will help the AI to make the ad": "Cette capture d'écran aidera l'IA à créer l'annonce",
          "Extra Costs (CHF)": "Coûts supplémentaires (CHF)",
          "Extra costs in CHF": "Coûts supplémentaires en CHF",
          "Price on Request": "Prix sur demande",
          "Ad Language": "Langue de l'annonce",
          "English": "Anglais",
          "German": "Allemand",
          "French": "Français",
          "Available from now": "Disponible dès maintenant",
          "Per Ad": "Par annonce",
          "Quick Links": "Liens rapides",
          "Follow Us": "Suivez-nous",
          "Redeem Code": "Utilisez le code",
          "Calculate Your Savings": "Calculez vos économies",
          "Ads per month": "Annonces par mois",
          "Employee Gross Annual Salary": "Salaire annuel brut de l'employé",
          "Minutes per ad": "Minutes par annonce",
          "You can save": "Vous pouvez économiser",
          "per year": "par an",
          "Welcome to ImmoAI": "Bienvenue chez ImmoAI",
          "Your AI-powered real estate ad creator.": "Votre générateur d'annonces immobilières propulsé par l'IA.",
          "Log In": "Connexion",
          "Don't have an account?": "Vous n'avez pas de compte?",
          "Sign up here": "Inscrivez-vous ici",
          "ImmoAI by DeepReturn": "ImmoAI par DeepReturn",
          "Number of rooms": "Nombre de pièces",
          "Enter number of rooms": "Entrez le nombre de pièces",
          "Viewing Appointments Information": "Informations sur les rendez-vous de visite",
          "You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.": "Vous pouvez ajouter des rendez-vous de visite pour les afficher dans l'annonce. Ceux-ci sont optionnels mais peuvent aider les locataires potentiels à planifier des visites plus facilement."
        }
      }
    },
    fallbackLng: "de",
    interpolation: {
      escapeValue: false // React already safes from XSS
    }
  });

export default i18n;
