import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ImageUpload.css';

const MAX_IMAGES = 10;
const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];

function ImageUpload({ setImages }) {
    const { t } = useTranslation();
    const [previewImages, setPreviewImages] = useState([]);
    const [dragOver, setDragOver] = useState(false);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const unsupportedFiles = files.filter(file => !ALLOWED_FORMATS.includes(file.type));
        
        if (unsupportedFiles.length > 0) {
            alert(t('Unsupported format') + ': ' + unsupportedFiles.map(file => file.type).join(', ') + '. ' + t('Supported formats') + ': ' + ALLOWED_FORMATS.join(', ') + '.');
            return;
        }

        const validFiles = files.filter(file => ALLOWED_FORMATS.includes(file.type));
        
        if (previewImages.length + validFiles.length > MAX_IMAGES) {
            alert(t(`You can upload a maximum of ${MAX_IMAGES} images.`));
            return;
        }

        const newImages = validFiles.map(file => URL.createObjectURL(file));

        setPreviewImages([...previewImages, ...newImages]);
        setImages(prevImages => [...prevImages, ...validFiles]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        const unsupportedFiles = files.filter(file => !ALLOWED_FORMATS.includes(file.type));

        if (unsupportedFiles.length > 0) {
            alert(t('Unsupported format') + ': ' + unsupportedFiles.map(file => file.type).join(', ') + '. ' + t('Supported formats') + ': ' + ALLOWED_FORMATS.join(', ') + '.');
            setDragOver(false);
            return;
        }

        const validFiles = files.filter(file => ALLOWED_FORMATS.includes(file.type));

        if (previewImages.length + validFiles.length > MAX_IMAGES) {
            alert(t(`You can upload a maximum of ${MAX_IMAGES} images.`));
            setDragOver(false);
            return;
        }

        const newImages = validFiles.map(file => URL.createObjectURL(file));

        setPreviewImages([...previewImages, ...newImages]);
        setImages(prevImages => [...prevImages, ...validFiles]);
        setDragOver(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleRemoveImage = (index, event) => {
        event.stopPropagation();
        const newPreviewImages = previewImages.filter((_, i) => i !== index);
        setPreviewImages(newPreviewImages);
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleClick = () => {
        document.getElementById('file-input').click();
    };

    return (
        <div 
            className={`image-upload-container ${dragOver ? 'drag-over' : ''}`} 
            onClick={handleClick}
            onDrop={handleDrop} 
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <input id="file-input" type="file" multiple onChange={handleImageChange} />
            {!previewImages.length && (
                <div className="drag-drop-text">
                    {t('Drag and drop your images here or click to select images')}
                    <div className="upload-info">
                        {t('Suggested images:')}
                        <ul>
                            <li>{t('Indoor pictures')} 📸</li>
                            <li>{t('Pictures of the surroundings')} 🌆</li>
                            <li>{t('Floor plan (Grundrissplan) if possible')} 📐</li>
                        </ul>
                    </div>
                </div>
            )}
            <div className="image-preview-container">
                {previewImages.map((image, index) => (
                    <div key={index} className="image-preview">
                        <img src={image} alt={`Preview ${index}`} />
                        <button type="button" onClick={(event) => handleRemoveImage(index, event)}>✖️</button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ImageUpload;
