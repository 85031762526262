import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import App from './App';
import './config/i18n'; // Import the i18n configuration
import { Auth0Provider } from '@auth0/auth0-react';

const root = createRoot(document.getElementById('root'));

// Function to handle redirection after login
const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

root.render(
  <Auth0Provider
    domain="deepreturn.us.auth0.com"
    clientId="uJ5tYgjyqhETn7PIABPbNoXpgpSrLJf3"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>
);
