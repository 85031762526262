import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <h1>{t('Privacy Policy')}</h1>
      <p>{t('privacyPolicyIntro')}</p>
      <p>{t('privacyPolicyUsage')}</p>
      <p>{t('privacyPolicyTransmission')}</p>
      <p>{t('privacyPolicyChanges')}</p>
    </div>
  );
};

export default PrivacyPolicy;
